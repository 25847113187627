<template>
  <div class="error-container">
    <h1>{{ $t('404') }}</h1>
    <p>{{ $t('notFound1') }}</p>
    <p>{{ $t('notFound2') }}</p>
    <button class="btn btn-apply" @click="handleGoBack">{{ $t('goBack') }}</button>
  </div>
</template>

<script>

export default {
  methods: {
    handleGoBack () {
      this.$router.go(-2)
    }
  }
}
</script>

<style lang="scss" scoped>
  .error-container {
    height: calc(100vh - 20px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      margin-bottom: 40px;
    }
    p {
      margin-bottom: 10px;
    }
    button {
      margin-top: 40px;
      width: 200px;
    }
  }
</style>
